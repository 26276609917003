export const applyNewBuildingEffect = (permanentResources: PermanentResources, building: BuildingType): PermanentResources => {
  switch (building) {
    case "house":
      return { ...permanentResources, citizens: permanentResources.citizens + 4 };
    case "blacksmith":
      return { ...permanentResources, multiplicator: permanentResources.multiplicator + 1 };
    case "temple":
      return { ...permanentResources, happiness: permanentResources.happiness + 10 };
  }
  return permanentResources;
};
