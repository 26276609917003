import { useContext, useEffect, useState } from "react";
import { GameEngineContext } from "../../GameEngine";

type Props = {
  children: JSX.Element | JSX.Element[];
};
const BackgroundMusic: React.FC<Props> = ({ children }) => {
  const tutorialCompleted = useContext(GameEngineContext).tutorialCompleted;
  const [trackNumber, setTrackNumber] = useState<number>(Math.floor(Math.random() * 9) + 1);
  const [audio] = useState(new Audio(`/music/soundtrack${trackNumber}.mp3`));
  //const [shouldStartPlaying, setShouldStartPlaying] = useState<boolean>(false);
  const [muteMusic, setMuteMusic] = useState<boolean>(false);
  const isEditing = useContext(GameEngineContext).editing.isEditing;

  // Start playing the music if the user finish the tutorial
  useEffect(() => {
    if (tutorialCompleted) {
      audio.onended = () => {
        setTrackNumber(trackNumber === 9 ? 1 : trackNumber + 1);
        audio.src = `/music/soundtrack${trackNumber}.mp3`;
        audio.load();
        audio.play();
      };
      audio.play();
      // Change audio source
      audio.volume = 0.05;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, tutorialCompleted]);

  useEffect(() => {
    if (isEditing) {
      audio.playbackRate = 0.5;
      audio.volume = 0.02;
    } else {
      audio.playbackRate = 1;
      audio.volume = 0.05;
    }
  }, [audio, isEditing]);

  const handleMuteMusic = () => {
    const muteMusicValue = !muteMusic;
    setMuteMusic(muteMusicValue);
    muteMusicValue ? (audio.muted = true) : (audio.muted = false);
  };

  return (
    <div className="overflow-hidden">
      <div
        className="absolute top-2 left-[330px] w-8 h-8 bg-beige-100 border-b-4 border-beige-300 cursor-pointer shadow-md text-beige-400 z-50 rounded-full text-sm transition-all duration-300 hover:opacity-70"
        onClick={handleMuteMusic}
      >
        {!muteMusic ? (
          <i className="fa-solid fa-volume-high relative left-1.5 top-1"></i>
        ) : (
          <i className="fa-solid fa-volume-xmark relative left-2 top-1"></i>
        )}
      </div>
      {children}
    </div>
  );
};

export default BackgroundMusic;
