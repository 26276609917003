import portait1 from "../../../assets/images/user/1.png";
import Nail from "../Nail";

const UserProfile = () => {
  return (
    <div className=" bg-beige-100 bg-wood w-80 rounded-br-xl border-b-4 border-beige-300 h-[88px] p-2 bg-center bg-cover absolute">
      <div className="flex">
        <div
          className="bg-beige-200 w-16 h-16 m-0.5 rounded-lg relative cursor-pointer transition-all duration-300 hover:opacity-70 border-b-4 border-beige-300 p-1"
          onClick={() => alert("Le changement d'avatar sera disponible dans une prochaine mise à jour.")}
        >
          <img src={portait1} alt="" />
          <Nail size={6} left={2} top={2} />
          <Nail size={6} right={2} top={2} />
        </div>
        <div className="w-56 p-2">
          <p className="font-bold text-beige-400">Nom du joueur</p>
          <div className="bg-beige-400 w-full h-4 mt-3 relative">
            <p className="text-center text-[10px] font-bold text-white">0 / 200 XP</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserProfile;
