import { createContext, useState } from "react";
import App from "./App";
import { getAchievmentsDefaultList } from "./components/achievments/getAchievmentsDefaultList";

const defaultGameEngine: GameEngine = {
  editing: { isEditing: false, selectedBuilding: null },
  cost: {
    house: { wood: 2, rock: 2, citizens: 0, happiness: 0, availableQuantity: "infinity" },
    blacksmith: { wood: 5, rock: 5, citizens: 0, happiness: 2, availableQuantity: "infinity" },
    temple: { wood: 10, rock: 10, citizens: 8, happiness: 0, availableQuantity: 1 },
  },
  resources: { wood: 0, rock: 0 },
  permanentResources: { citizens: 0, happiness: 0, multiplicator: 1 },
  map: [],
  tutorialCompleted: false,
  setGameEngine: (any) => any,
  achievments: getAchievmentsDefaultList(),
};
export const GameEngineContext = createContext<GameEngine>(defaultGameEngine);

const GameEngine: React.FC = () => {
  const [gameEngine, setGameEngine] = useState<GameEngine>(defaultGameEngine);
  return (
    <GameEngineContext.Provider value={{ ...gameEngine, setGameEngine: setGameEngine }}>
      <App />
    </GameEngineContext.Provider>
  );
};
export default GameEngine;
