import { useContext, useEffect } from "react";
import Shop from "./components/ui/Shop/Shop";
import Header from "./components/ui/Header/Header";
import BackgroundMusic from "./components/audio/BackgroundMusic";
import { GameEngineContext } from "./GameEngine";
import Map from "./components/Map";
import Changelog from "./components/ui/Changelog/Changelog";
import Tutorial from "./components/Tutorial/Tutorial";
import Multiplicator from "./components/ui/Multiplicator/Multiplicator";
import Achievments from "./components/achievments/Achievments";
import UserProfile from "./components/ui/UserProfile/UserProfile";
import { useWindowSize } from "./utils/hooks/useWindowSize";
import MobileScreen from "./components/MobileScreen";

const App: React.FC = () => {
  const gameEngine = useContext(GameEngineContext);

  // Handle window size
  const [width] = useWindowSize();

  useEffect(() => {
    // Handle the editing mode
    if (gameEngine.editing.isEditing) {
      document.body.classList.add("editing-mode");
    } else {
      document.body.classList.remove("editing-mode");
    }
  }, [gameEngine]);

  if (!gameEngine.tutorialCompleted) {
    return <Tutorial />;
  }

  return (
    <BackgroundMusic>
      {width > 1350 ? (
        <div className="w-full h-screen">
          <Header />
          <UserProfile />
          <Changelog />
          <Multiplicator />
          <Achievments />
          <div className="h-screen w-screen flex items-center justify-center">
            <div className="w-fit mx-auto px-32 overflow-auto py-4">
              <div
                className={
                  (gameEngine.editing.isEditing ? "grayscale-[75%]" : "") + " w-[327px] h-[310px] relative drop-shadow-lg mx-auto"
                }
              >
                <Map />
              </div>
            </div>
          </div>
          <Shop />
        </div>
      ) : (
        <MobileScreen />
      )}
    </BackgroundMusic>
  );
};
export default App;
