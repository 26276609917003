import house from "../assets/images/buildings/house.png";
import blacksmith from "../assets/images/buildings/blacksmith.png";
import temple from "../assets/images/buildings/temple.png";
import citizens from "../assets/images/citizens.png";
import happiness from "../assets/images/happiness.png";

export const getShopInfoFromName = (name: string): Omit<ShopInfo, "cost"> => {
  switch (name) {
    case "house":
      return {
        image: house,
        type: "house",
        description: <p className="inline-block">Une maison pour accueillir des villageois.</p>,
        additionnalInfo: (
          <p className="inline-block">
            +4
            <img src={citizens} alt="" className="w-3 h-3 inline ml-0.5 relative bottom-0.5" />
          </p>
        ),
      };
    case "blacksmith":
      return {
        image: blacksmith,
        type: "blacksmith",
        description: <p>Un forgeron pour améliorer la qualité de vos outils.</p>,
        additionnalInfo: <p>+1 au multiplicateur</p>,
      };
    case "temple":
      return {
        image: temple,
        type: "temple",
        description: <p>Un temple qui permettra à vos villageois de prier pour plus de bonheur.</p>,
        additionnalInfo: (
          <p>
            +10
            <img src={happiness} alt="" className="w-3 h-3 inline ml-0.5 relative bottom-0.5" />
          </p>
        ),
      };
    default:
      return {
        image: "",
        type: "house",
        description: <></>,
        additionnalInfo: <></>,
      };
  }
};
