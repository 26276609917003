import { useContext, useEffect, useState } from "react";
import { GameEngineContext } from "../../GameEngine";
import Nail from "../ui/Nail";

const Achievments: React.FC = () => {
  const gameEngine = useContext(GameEngineContext);
  const achievments = gameEngine.achievments;

  const [achievmentToDisplay, setAchievmentToDisplay] = useState<Achievment | null>(null);
  const [showAchievments, setShowAchievments] = useState<boolean>(false);

  useEffect(() => {
    // Detect if the achievment is achieved
    for (const [, value] of Object.entries(achievments)) {
      if (!value.achieved && value.condition(gameEngine)) {
        // Set the achievment as achieved + set the gameEngine
        value.achieved = true;
        gameEngine.setGameEngine({ ...gameEngine });

        // Set the achievment to display
        setAchievmentToDisplay(value);

        // Hide the achievment after 5 seconds
        setTimeout(() => {
          setAchievmentToDisplay(null);
        }, 4000);
      }
    }
  }, [gameEngine, achievments]);

  return (
    <>
      {achievmentToDisplay && (
        <div className="w-full h-20 absolute top-36">
          <div className="w-96 mx-auto h-full border-beige-300 border-b-4 text-beige-400 bg-beige-100 p-2.5 flex shadow-md bg-wood bg-center bg-cover rounded-xl">
            <div className=" bg-beige-200 p-1.5 rounded-lg border-b-4 border-beige-300 relative">
              <Nail size={6} left={4} top={4} />
              <Nail size={6} right={4} top={4} />
              <img src={achievmentToDisplay.image} alt="" className="w-10" />
            </div>
            <div className="pl-2">
              <p className="font-bold">{achievmentToDisplay.title}</p>
              <p className="text-sm">{achievmentToDisplay.description}</p>
            </div>
          </div>
        </div>
      )}
      <div
        onClick={() => setShowAchievments(true)}
        className="absolute top-2 right-20 w-16 h-16 bg-beige-100 border-b-4 border-beige-300 cursor-pointer shadow-md z-50 rounded-full bg-wood bg-cover bg-center text-beige-400 text-2xl hover:opacity-70 transition-all duration-200"
      >
        <p className="font-bold relative mt-3.5 text-center">
          <i className="fa-solid fa-trophy"></i>
        </p>
      </div>
      {showAchievments && (
        <>
          <div className="bg-beige-400 w-screen h-screen absolute z-top top-0 opacity-80"></div>
          <div className="bg-brown-100 border-b-4 border-beige-300 w-[90%] h-[90%] absolute z-top left-[5%] top-[5%] text-beige-400 overflow-hidden select-none rounded-xl bg-wood bg-cover bg-center">
            <h1 className="text-center font-madimi text-3xl py-6">Succès</h1>
            <div className="bg-beige-300 h-1 w-full"></div>
            <div className="bg-beige-200 w-full h-full p-4 overflow-y-auto pb-48">
              <div className="grid grid-cols-3 gap-4">
                {Object.entries(achievments).map(([key, value]) => {
                  return (
                    <div
                      key={key}
                      className={
                        "w-full h-24 flex items-center justify-between border-b-4 border-beige-300 mb-2 bg-beige-100 p-2 rounded-lg bg-wood bg-cover bg-center"
                      }
                    >
                      <div className="flex items-center px-2">
                        <div className="bg-beige-200 p-1.5 rounded-lg border-b-4 border-beige-300 relative">
                          <Nail size={6} left={4} top={4} />
                          <Nail size={6} right={4} top={4} />
                          <img src={value.image} alt="" className="w-10" />
                        </div>
                        <div className="pl-2">
                          <p className="font-bold">{value.title}</p>
                          <p className="text-sm">{value.description}</p>
                        </div>
                      </div>
                      {value.achieved && (
                        <div className="w-14 h-14 rounded-full border-green-500 border-4 flex items-center justify-center mr-4">
                          <i className="fa-solid fa-check text-4xl text-green-500"></i>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="bg-brown-100 border-t-4 border-beige-300 w-full h-20 absolute bottom-0">
              <div
                className="w-fit bg-beige-200 border-b-4 border-beige-300 px-8 py-1 text-xl mx-auto mt-5 cursor-pointer select-none hover:opacity-70 transition-all duration-200 rounded-lg"
                onClick={() => setShowAchievments(false)}
              >
                <i className="fa-solid fa-xmark mr-2"></i>Fermer
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Achievments;
