import { getNameForShopItem } from "../../../utils/getNameForShopItem";
import wood from "../../../assets/images/resources/wood.png";
import rock from "../../../assets/images/resources/rock.png";
import { useContext } from "react";
import { GameEngineContext } from "../../../GameEngine";
import Nail from "../Nail";

type Props = {
  image: string;
  type: BuildingType;
  description: JSX.Element;
  additionnalInfo: JSX.Element;
};

const ShopInformation: React.FC<Props> = ({ image, type, description, additionnalInfo }) => {
  const cost = useContext(GameEngineContext).cost[type];
  const available = cost.availableQuantity === "infinity" || cost.availableQuantity >= 1;

  return (
    <div className="bg-beige-100 rounded-lg border-b-4 border-beige-300 w-fit h-28 mx-auto relative bottom-[300px] z-20">
      <div className="flex">
        <div className="w-fit pt-4 pl-4">
          <div className="bg-beige-200 p-1.5 rounded-lg border-b-4 border-beige-300 relative">
            <Nail size={6} left={4} top={4} />
            <Nail size={6} right={4} top={4} />
            <img src={image} alt="" className="w-10" />
          </div>
        </div>
        <div className="pt-4 pl-2 pr-4">
          <h2 className="text-beige-400 font-bold flex">
            {getNameForShopItem(type)}{" "}
            {available && (
              <span className="font-normal text-sm flex ml-1 top-0.5 relative">
                ({cost.wood} <img src={wood} alt="" className="flex w-3 h-3 ml-1 mr-1 mt-1" /> {cost.rock}{" "}
                <img src={rock} alt="" className="flex w-3 h-3 ml-1 mt-1" />)
              </span>
            )}
          </h2>
          <div className="text-xs text-beige-400">{additionnalInfo}</div>
          <div>
            <p className="text-xs text-beige-400">
              Quantité restante : {cost.availableQuantity === "infinity" ? "∞" : cost.availableQuantity}
            </p>
          </div>
        </div>
      </div>
      <div className="text-xs text-beige-400 italic mt-2 opacity-70 h-fit block px-2">{description}</div>
    </div>
  );
};

export default ShopInformation;
