// Pourrait être dans un dictionnaire
export const getNameForShopItem = (type: BuildingType) => {
  switch (type) {
    case "house":
      return "Maison";
    case "blacksmith":
      return "Forgeron";
    case "temple":
      return "Temple";
  }
};
