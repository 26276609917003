import { useContext, useState } from "react";
import { GameEngineContext } from "../../../GameEngine";

const Multiplicator: React.FC = () => {
  const { multiplicator } = useContext(GameEngineContext).permanentResources;
  const { map } = useContext(GameEngineContext);
  const numberOfBlacksmith = map.filter((e) => e.props.buildingType === "blacksmith").length;
  const [showMultiplicatorTooltip, setShowMultiplicatorTooltip] = useState<boolean>(false);
  return (
    <>
      {showMultiplicatorTooltip && (
        <div className="absolute top-20 right-2">
          <div>
            <div className="w-0 h-0 border-l-8 border-r-8 border-b-8 border-l-transparent border-r-transparent border-b-beige-200 ml-auto mr-6"></div>
            <div className="bg-beige-200 border-b-4 border-beige-300 px-4 py-2 text-beige-400 rounded-xl">
              <p className="font-bold">Bonus de multiplication de clic</p>
              {multiplicator > 1 ? (
                <div className="text-sm">{numberOfBlacksmith >= 1 && <p>Forgeron : + {numberOfBlacksmith}</p>}</div>
              ) : (
                <p className="text-center text-sm">Aucun multiplicateur pour le moment</p>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        onMouseEnter={() => setShowMultiplicatorTooltip(true)}
        onMouseLeave={() => setShowMultiplicatorTooltip(false)}
        className="absolute top-2 right-2 w-16 h-16 bg-beige-100 border-b-4 border-beige-300 cursor-pointer shadow-md z-50 rounded-full bg-wood bg-cover bg-center text-beige-400 text-2xl"
      >
        <p className="font-bold text-center mt-3.5">x{multiplicator}</p>
      </div>
    </>
  );
};
export default Multiplicator;
