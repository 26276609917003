import Case from "../../components/Case";

export const populateRock = (map: JSX.Element[], numberOfRock: number) => {
  let randomIndex = Array.from({ length: numberOfRock }, () => Math.floor(Math.random() * map.length));

  randomIndex.forEach((rIndex) => {
    map[rIndex] = (
      <Case
        key={rIndex}
        ground={map[rIndex].props.ground}
        positionX={map[rIndex].props.positionX}
        positionY={map[rIndex].props.positionY}
        buildingType={"rock"}
        caseId={map[rIndex].props.index}
      />
    );
  });
};
