import { useState } from "react";

const Changelog: React.FC = () => {
  const [showChangelog, setShowChangelog] = useState<boolean>(false);
  return (
    <>
      {showChangelog && (
        <>
          <div className="bg-beige-400 w-screen h-screen absolute z-top top-0 opacity-80"></div>
          <div className="bg-brown-100 border-b-4 border-beige-300 w-[90%] h-[90%] absolute z-top left-[5%] top-[5%] text-beige-400 overflow-hidden select-none rounded-xl bg-wood bg-cover bg-center">
            <h1 className="text-center font-madimi text-3xl py-6">Historique de mise à jour</h1>
            <div className="bg-beige-300 h-1 w-full"></div>
            <div className="bg-beige-200 w-full h-full p-4 overflow-y-auto pb-48">
              <h1 className="font-bold text-xl pt-2">0.0.7</h1>
              <ul className="text-sm list-disc px-4">
                <li>Ajout du profil utilisateur</li>
                <li>Nouvelle UI</li>
              </ul>
              <h1 className="font-bold text-xl pt-2">0.0.6</h1>
              <ul className="text-sm list-disc px-4">
                <li>Ajout des succès</li>
                <li>Correction d'un bug sur le layout de la page sur Firefox</li>
              </ul>
              <h1 className="font-bold text-xl pt-2">0.0.5</h1>
              <ul className="text-sm list-disc px-4">
                <li>Ajout des quantité maximale de buildings</li>
                <li>Ajout du multiplicateur de clic</li>
              </ul>
              <h1 className="font-bold text-xl pt-2">0.0.4</h1>
              <ul className="text-sm list-disc px-4">
                <li>Correction d'un bug qui gardait les éléments du magasin sélectionnés</li>
              </ul>
              <h1 className="font-bold text-xl pt-2">0.0.3</h1>
              <ul className="text-sm list-disc px-4">
                <li>Ajout de la page de tutoriel</li>
                <li>Correction d'un bug sur les musiques</li>
                <li>Correction d'un bug sur l'affichage de la description des objets dans le magasin</li>
              </ul>
              <h1 className="font-bold text-xl pt-2">0.0.2</h1>
              <ul className="text-sm list-disc px-4">
                <li>Correction de bugs</li>
                <li>Ajout du bouton on/off pour la musique</li>
                <li>Ajout du bouton et de la fenêtre changelog</li>
                <li>Ajout des blocages pour les items de la boutique</li>
                <li>Ajout des citoyens / joie</li>
                <li>Ajout du temple</li>
              </ul>
              <h1 className="font-bold text-xl pt-2">0.0.1</h1>
              <ul className="text-sm list-disc px-4">
                <li>Création du jeu</li>
              </ul>
            </div>
            <div className="bg-brown-100 border-t-4 border-beige-300 w-full h-20 absolute bottom-0">
              <div
                className="w-fit bg-beige-200 border-b-4 border-beige-300 px-8 py-1 text-xl mx-auto mt-5 cursor-pointer select-none hover:opacity-70 transition-all duration-200 rounded-lg"
                onClick={() => setShowChangelog(false)}
              >
                <i className="fa-solid fa-xmark mr-2"></i>Fermer
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className="absolute top-12 left-[330px] w-8 h-8 bg-beige-100 border-b-4 border-beige-300 cursor-pointer shadow-md text-beige-400 z-50 rounded-full text-sm transition-all duration-300 hover:opacity-70"
        onClick={() => setShowChangelog(true)}
      >
        <i className="fa-solid fa-newspaper relative left-2 top-1"></i>
      </div>
    </>
  );
};
export default Changelog;
