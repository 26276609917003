import ShopItem from "./ShopItem";
import { useContext, useState } from "react";
import ShopInformation from "./ShopInformation";
import { GameEngineContext } from "../../../GameEngine";

const Shop: React.FC = () => {
  const [shopInfo, setShopInfo] = useState<ShopInfo | null>(null);
  const shopItems = useContext(GameEngineContext).cost;

  return (
    <>
      {shopInfo && <ShopInformation {...shopInfo} />}
      <div className="w-full absolute bottom-0 bg-beige-100 bg-wood bg-cover bg-center rounded-lg">
        <div className="flex w-fit mx-auto py-2">
          {Object.keys(shopItems).map((item, index) => {
            return <ShopItem type={item as BuildingType} displayShopInfo={setShopInfo} key={index} />;
          })}
        </div>
      </div>
    </>
  );
};
export default Shop;
