import placeholder from "../../assets/images/placeholder-100-100.png";

export const getAchievmentsDefaultList = () => {
  return {
    firstHouse: {
      title: "Première maison !",
      description: "Construisez votre première maison.",
      achieved: false,
      image: placeholder,
      condition: (gameEngine: GameEngine) => gameEngine.map.some((building) => building.props.buildingType === "house"),
    },
  };
};
