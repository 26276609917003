import { useContext, useState } from "react";
import Building from "./Building";
import { isResourceType } from "../utils/typeCheckers";
import { GameEngineContext } from "../GameEngine";
import { increaseCost } from "../utils/increaseCost";
import { decreaseResources } from "../utils/decreaseResources";
import { applyNewBuildingEffect } from "../utils/applyNewBuildingEffect";

type Props = {
  ground: string;
  positionX: number;
  positionY: number;
  buildingType: BuildingType | ResourceType | null;
  caseId: number;
};

const Case: React.FC<Props> = ({ ground, positionX, positionY, caseId, buildingType }) => {
  const [shouldShowAnimation, setShouldShowAnimation] = useState<boolean>(false);
  const { setGameEngine } = useContext(GameEngineContext);
  const gameEngine = useContext(GameEngineContext);

  const onClick = () => {
    // If the building is a resource type
    if (isResourceType(buildingType)) {
      // Play the animation
      setShouldShowAnimation(true);
      setTimeout(function () {
        setShouldShowAnimation(false);
      }, 200);

      // Update the resources
      setGameEngine((prev: GameEngine) => {
        return {
          ...prev,
          resources: {
            ...prev.resources,
            [buildingType || "wood"]: prev.resources[buildingType || "wood"] + prev.permanentResources.multiplicator,
          },
        };
      });
    }
    // If the building is an empty case
    else {
      if (gameEngine.editing.isEditing) {
        let updatedMap = [...gameEngine.map];
        // Spread the new building type
        updatedMap[caseId] = (
          <Case
            key={caseId}
            ground={ground}
            positionX={positionX}
            positionY={positionY}
            buildingType={gameEngine.editing.selectedBuilding}
            caseId={caseId}
          />
        );

        // Update the editing data & apply new building effect
        setGameEngine((prev: GameEngine) => ({
          ...prev,
          map: updatedMap,
          editing: {
            isEditing: false,
            selectedBuilding: null,
          },
          cost: increaseCost(gameEngine.cost, gameEngine.editing.selectedBuilding),
          resources: decreaseResources(gameEngine.resources, gameEngine.cost, gameEngine.editing.selectedBuilding || "house"),
          permanentResources: applyNewBuildingEffect(
            gameEngine.permanentResources,
            gameEngine.editing.selectedBuilding || "house"
          ),
        }));
      }
    }
  };

  return (
    <div
      className="absolute select-none cursor-pointer hover:animate-bounce transition-all duration-300 hover:opacity-90 w-[50px] h-[50px]"
      style={{ top: positionY, left: positionX }}
      onClick={onClick}
    >
      {buildingType && <Building type={buildingType} />}
      {shouldShowAnimation && (
        <p className="absolute font-extrabold text-white shadow-md left-[10px] bottom-[30px]">
          + {gameEngine.permanentResources.multiplicator}
        </p>
      )}
      <img src={ground} alt="" className="w-[50px] h-[50px]" />
    </div>
  );
};
export default Case;
