import { useContext, useEffect } from "react";
import { GameEngineContext } from "../GameEngine";
import { generateMap } from "../utils/generateMap";

const Map: React.FC = () => {
  const { setGameEngine } = useContext(GameEngineContext);
  const gameEngine = useContext(GameEngineContext);

  // Generate the map at the beginning
  useEffect(() => {
    setGameEngine((prev: GameEngine) => ({ ...prev, map: generateMap() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {gameEngine.map
        .filter((e) => (gameEngine.editing.isEditing ? !e.props.buildingType : true))
        .map((e, index) => (
          <div key={index} className="relative">
            {e}
          </div>
        ))}
    </>
  );
};
export default Map;
