import Case from "../components/Case";
import grass from "./../assets/images/grass.png";
import { populateRock } from "./populators/populateRock";
import { populateWood } from "./populators/populateWood";

export const generateMap = () => {
  const map = [];
  let x = 1;
  let y = 0;
  let numberOfCasesPerLine = 7;
  let counter = 0;
  let shouldStayTheSameLengthCounter = 0;
  let shouldStateTheSameLength = false;
  let shouldInverse = false;
  for (let i = 0; i < 205; i++) {
    map.push(<Case key={i} ground={grass} positionX={x} positionY={y} buildingType={null} caseId={i} />);
    x += 46;
    counter++;

    if (shouldStayTheSameLengthCounter >= 7 && counter === numberOfCasesPerLine) {
      shouldInverse = true;
      numberOfCasesPerLine--;
      counter = 0;
      x = -23 * numberOfCasesPerLine + 54 * 3;
      y += 13;
    }
    if (counter === numberOfCasesPerLine && !shouldStateTheSameLength && !shouldInverse) {
      numberOfCasesPerLine++;
      counter = 0;
      x = -23 * numberOfCasesPerLine + 54 * 3;
      y += 13;
    }
    if (numberOfCasesPerLine === 12 && shouldStayTheSameLengthCounter < 7) {
      shouldStayTheSameLengthCounter++;
      shouldStateTheSameLength = true;
      counter = 0;
      x = -23 * numberOfCasesPerLine + 54 * 3 + 46;
      numberOfCasesPerLine -= 2;
    } else {
      shouldStateTheSameLength = false;
    }
  }
  populateWood(map, 4);
  populateRock(map, 3);
  return map;
};
