import { getBuildingPositionOnCase } from "../utils/getBuildingPositionOnCase";
import { getImageFromBuildingType } from "../utils/getImageFromBuildingType";

type Props = {
  type: BuildingType | ResourceType | null;
};

const Building: React.FC<Props> = ({ type }) => {
  const size = getBuildingPositionOnCase(type);
  return (
    <img
      src={getImageFromBuildingType(type)}
      alt=""
      style={{ height: size.height, width: size.width, left: size.left, bottom: size.bottom }}
      className="absolute"
    ></img>
  );
};
export default Building;
