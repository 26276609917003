export const getBuildingPositionOnCase = (building: ResourceType | BuildingType | null) => {
  let size = {
    height: 0,
    width: 0,
    left: 0,
    bottom: 0,
  };
  switch (building) {
    case "wood":
      size = {
        height: 30,
        width: 20,
        left: 14,
        bottom: 30,
      };
      break;
    case "rock":
      size = {
        height: 20,
        width: 20,
        left: 14,
        bottom: 30,
      };
      break;
    case "house":
      size = {
        height: 30,
        width: 30,
        left: 10,
        bottom: 27,
      };
      break;
    case "blacksmith":
      size = {
        height: 30,
        width: 30,
        left: 10,
        bottom: 27,
      };
      break;
    case "temple":
      size = {
        height: 34,
        width: 34,
        left: 8,
        bottom: 26,
      };
      break;
  }
  return size;
};
