import { useContext } from "react";
import { GameEngineContext } from "../../GameEngine";
import citizens from "../../assets/images/citizens.png";
import happiness from "../../assets/images/happiness.png";
import wood from "../../assets/images/resources/wood.png";
import rock from "../../assets/images/resources/rock.png";

const Tutorial: React.FC = () => {
  const { setGameEngine } = useContext(GameEngineContext);

  const handleTutorial = () => {
    setGameEngine((prev: GameEngine) => ({ ...prev, tutorialCompleted: true }));
  };

  return (
    <>
      <>
        <div className="bg-beige-400 w-screen h-screen absolute z-top top-0 opacity-80"></div>
        <div className="bg-brown-100 border-b-4 border-beige-300 w-[90%] h-[90%] absolute z-top left-[5%] top-[5%] text-beige-400 overflow-hidden select-none rounded-xl bg-wood bg-cover bg-center">
          <h1 className="text-center font-madimi text-3xl py-6">Tutoriel</h1>
          <div className="bg-beige-300 h-1 w-full"></div>
          <div className="bg-beige-200 w-full h-full p-4 overflow-y-auto pb-48">
            <div className="px-16 text-center pt-20">
              <p>Bienvenue sur Tiny Cute Town !</p>
              <br />
              <p>
                Le but de ce jeu est d'avoir la ville la plus choupi possible afin que ses petits habitants{" "}
                <img src={citizens} alt="" className="inline w-4 relative bottom-0.5 -ml-0.5" /> soient les plus heureux{" "}
                <img src={happiness} alt="" className="inline w-4 relative bottom-0.5 -ml-0.5" /> du monde !
              </p>
              <br />
              <p>
                Pour cela, vous devrez récolter des ressources{" "}
                <img src={rock} alt="" className="inline w-4 relative bottom-0.5 -ml-0.5" />{" "}
                <img src={wood} alt="" className="inline w-4 relative bottom-0.5 -ml-0.5" /> afin d'acheter des bâtiments, qui
                vous permettront soit, de récolter encore plus de ressources, soit d'augmenter le bonheur de vos citoyens.
              </p>
              <br />
              <p>
                N'oubliez pas que vous devez <u>tout</u> faire pour que vous citoyens soient les plus heureux !
                <br />À vous d'être un bon maire, de construire les bonnes installations et de gérer votre ville comme il se doit
                !
              </p>
              <br />
              <p>Bon jeu !</p>
            </div>
          </div>
          <div className="bg-brown-100 border-t-4 border-beige-300 w-full h-20 absolute bottom-0">
            <div
              className="w-fit bg-beige-200 border-b-4 border-beige-300 px-8 py-1 text-xl mx-auto mt-5 cursor-pointer select-none hover:opacity-70 transition-all duration-200 rounded-lg"
              onClick={handleTutorial}
            >
              <i className="fa-solid fa-check mr-2"></i>J'ai compris !
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default Tutorial;
