import Case from "../../components/Case";

export const populateWood = (map: JSX.Element[], numberOfTree: number) => {
  let randomIndex = Array.from({ length: numberOfTree }, () => Math.floor(Math.random() * map.length));

  randomIndex.forEach((rIndex) => {
    map[rIndex] = (
      <Case
        key={rIndex}
        ground={map[rIndex].props.ground}
        positionX={map[rIndex].props.positionX}
        positionY={map[rIndex].props.positionY}
        buildingType={"wood"}
        caseId={map[rIndex].props.index}
      />
    );
  });
};
