import wood from "../../../assets/images/resources/wood.png";
import rock from "../../../assets/images/resources/rock.png";
import citizens from "../../../assets/images/citizens.png";
import happiness from "../../../assets/images/happiness.png";
import { useContext, useState } from "react";
import { GameEngineContext } from "../../../GameEngine";
import Nail from "../Nail";

const Header: React.FC = () => {
  const resources = useContext(GameEngineContext).resources;
  const permanentResources = useContext(GameEngineContext).permanentResources;

  const [showResourcesTooltip, setShowResourcesTooltip] = useState<boolean>(false);

  return (
    <div className="absolute top-0 w-full">
      <div className="bg-beige-100 border-b-4 border-beige-300 select-none w-fit px-52 pb-6 pt-2 bg-wood bg-center bg-cover mx-auto rounded-b-xl">
        <h1 className="font-madimi text-center text-3xl text-brown-400 py-2">Tiny Cute Town</h1>
      </div>
      <div
        className="w-fit mx-auto bg-beige-200 border-b-4 border-beige-300 select-none text-brown-400 px-8 py-4 rounded-lg relative -top-6"
        onMouseEnter={() => setShowResourcesTooltip(true)}
        onMouseLeave={() => setShowResourcesTooltip(false)}
      >
        <Nail left={4} top={4} size={12} />
        <Nail right={4} top={4} size={12} />
        <div className="w-fit mx-auto flex">
          <p className="mx-2 flex font-bold text-beige-400">
            <img src={wood} alt="" className="w-6 h-6 mr-2" /> {resources.wood}
          </p>
          <p className="mx-2 flex font-bold text-beige-400">
            <img src={rock} alt="" className="w-6 h-6 mr-2" /> {resources.rock}
          </p>
          <p className="mx-2 flex font-bold text-beige-400">
            <img src={citizens} alt="" className="w-6 h-6 mr-2" /> {permanentResources.citizens}
          </p>
          <p className="mx-2 flex font-bold text-beige-400">
            <img src={happiness} alt="" className="w-6 h-6 mr-2" /> {permanentResources.happiness}
          </p>
        </div>
      </div>
      {showResourcesTooltip && (
        <div className="relative -top-4 z-50">
          <div className="w-0 h-0 border-l-8 border-r-8 border-b-8 border-l-transparent border-r-transparent border-b-beige-200 mx-auto"></div>
          <div className="bg-beige-200 border-b-4 border-beige-300 w-fit mx-auto rounded-lg p-4 text-beige-400 text-sm text-center">
            <p className="mb-1">
              Les <img src={wood} alt="" className="w-4 h-4 mx-1 inline" />
              peuvent être récoltés en coupant des arbres
            </p>
            <p className="mb-1">
              Les <img src={rock} alt="" className="w-4 h-4 top-1 inline" /> peuvent être récoltés en cassant des rochers
            </p>
            <p className="mb-1">
              Les <img src={citizens} alt="" className="w-4 h-4 top-1 inline" /> peuvent être obtenus en construisant des maisons
            </p>
            <p>
              Les <img src={happiness} alt="" className="w-4 h-4 top-1 inline" /> peuvent être obtenus en construisant certains
              batiments
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
export default Header;
