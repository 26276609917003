export const increaseCost = (oldCost: Cost, selectedBuilding: BuildingType | null) => {
  const newCost = { ...oldCost };
  switch (selectedBuilding) {
    case "house":
      return { ...newCost, house: { ...newCost["house"], wood: newCost.house.wood * 2, rock: newCost.house.rock * 2 } };
    case "blacksmith":
      return {
        ...newCost,
        blacksmith: { ...newCost["blacksmith"], wood: newCost.blacksmith.wood * 2, rock: newCost.blacksmith.rock * 2 },
      };
    case "temple":
      return {
        ...newCost,
        temple: {
          ...newCost["temple"],
          wood: newCost.temple.wood * 2,
          rock: newCost.temple.rock * 2,
          availableQuantity: (newCost.temple.availableQuantity as number) - 1,
        },
      };
  }
};
