import { useContext, useEffect, useState } from "react";
import { getNameForShopItem } from "../../../utils/getNameForShopItem";
import wood from "../../../assets/images/resources/wood.png";
import rock from "../../../assets/images/resources/rock.png";
import citizens from "../../../assets/images/citizens.png";
import happiness from "../../../assets/images/happiness.png";
import { GameEngineContext } from "../../../GameEngine";
import { getImageFromBuildingType } from "../../../utils/getImageFromBuildingType";
import { getShopInfoFromName } from "../../../utils/getShopInfoFromName";

type Props = {
  type: BuildingType;
  displayShopInfo: React.Dispatch<React.SetStateAction<ShopInfo | null>>;
};

const ShopItem: React.FC<Props> = ({ type, displayShopInfo }) => {
  const cost = useContext(GameEngineContext).cost[type];
  const { setGameEngine, editing } = useContext(GameEngineContext);
  const { resources, permanentResources } = useContext(GameEngineContext);

  // If the resource is unlocked (enough citizens/happiness)
  const isUnlocked = permanentResources.citizens >= cost.citizens && permanentResources.happiness >= cost.happiness;

  // If the resource can be purchased (enough quantity)
  const available = cost.availableQuantity === "infinity" || cost.availableQuantity >= 1;

  // If the resource is affordable :
  // - Enough wood / rock
  // - Enough citizens / happiness
  // - Enough available quantity
  const canAfford = resources.wood >= cost.wood && resources.rock >= cost.rock && isUnlocked && available;
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const canClick = () => {
    if (canAfford) {
      setGameEngine((prev: { editing: { isEditing: any } }) => {
        return {
          ...prev,
          editing: {
            isEditing: !prev.editing.isEditing,
            selectedBuilding: prev.editing.isEditing ? null : type,
          },
        };
      });
      setIsSelected(!isSelected);
    }
  };

  // Reset the selected state when the user can't afford the building anymore
  useEffect(() => {
    if (!editing.isEditing) setIsSelected(false);
  }, [editing.isEditing]);

  return (
    <>
      <div
        className={
          (isSelected ? "bottom-8" : "") +
          (available ? "" : " !cursor-not-allowed !opacity-40") +
          " mx-2 bg-beige-200 rounded-lg p-2 border-b-4 border-beige-300 select-none shadow-md w-28 relative"
        }
      >
        <div
          onMouseEnter={() => displayShopInfo({ ...getShopInfoFromName(type), cost })}
          onMouseLeave={() => displayShopInfo(null)}
          className="bg-beige-100 rounded-full w-8 h-8 absolute text-center border-b-4 border-beige-300 -top-4 cursor-pointer left-5"
        >
          <i className="fa-solid fa-info text-beige-400 relative top-0.5"></i>
        </div>
        <div
          className={
            (canAfford ? "cursor-pointer hover:opacity-70 transition-all duration-200" : "grayscale-[80%]") +
            " rounded-full w-8 h-8 absolute text-center border-b-4 border-beige-300 -top-4 right-5 bg-beige-100"
          }
          onClick={() => canClick()}
        >
          {!isSelected ? (
            <>
              {canAfford ? "" : <div className="bg-beige-400 w-0.5 h-7 absolute rotate-45 left-[13px]"></div>}
              <i className="fa-solid fa-dollar-sign text-beige-400 relative top-0.5"></i>
            </>
          ) : (
            <i className="fa-solid fa-xmark text-beige-400 relative top-0.5"></i>
          )}
        </div>
        <img src={getImageFromBuildingType(type)} alt="" className="w-8 mx-auto pt-2" />
        {!isUnlocked && (
          <div className="bg-beige-100 absolute w-20 z-10 right-4 bottom-2 h-12 text-beige-400 rounded-lg">
            <div className="mx-auto w-fit">
              <i className="fa-solid fa-lock text-xs"></i>
            </div>
            <div className="flex text-xs w-fit mx-auto">
              <p>{cost.citizens}</p>
              <img src={citizens} alt="" className="inline w-3 h-3 ml-0.5 relative top-0.5 mr-2" />
              <p>{cost.happiness}</p>
              <img src={happiness} alt="" className="inline w-3 h-3 ml-0.5 relative top-0.5" />
            </div>
          </div>
        )}
        <div className="flex justify-center text-beige-400">
          <div className="text-center">
            <p className="text-sm font-bold">{getNameForShopItem(type)}</p>
            {available && (
              <>
                <div>
                  <p className="text-sm inline mx-1">
                    {cost.wood}
                    <img src={wood} alt="" className="inline w-4 h-4 ml-1 relative bottom-0.5" />
                  </p>
                </div>
                <div>
                  <p className="text-sm inline mx-1">
                    {cost.rock}
                    <img src={rock} alt="" className="inline w-4 h-4 ml-1 relative bottom-0.5" />
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ShopItem;
