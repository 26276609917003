type Props = {
  left?: number;
  top?: number;
  bottom?: number;
  right?: number;
  size?: number;
};

const Nail: React.FC<Props> = ({ left, top, bottom, right, size }) => {
  return (
    <div
      style={{ left: left, top: top, bottom: bottom, right: right, width: size, height: size }}
      className="absolute bg-nail-200 border-b-2 border-nail-100 rounded-full"
    />
  );
};

export default Nail;
