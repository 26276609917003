import house from "../assets/images/buildings/house.png";
import blacksmith from "../assets/images/buildings/blacksmith.png";
import temple from "../assets/images/buildings/temple.png";
import wood from "../assets/images/wood.png";
import rock from "../assets/images/rock.png";

export const getImageFromBuildingType = (type: BuildingType | ResourceType | null) => {
  const dictionary = {
    // Ressources
    wood: wood,
    rock: rock,
    // Buildings
    house: house,
    blacksmith: blacksmith,
    temple: temple,
    // Default
    null: "",
  };
  return dictionary[type || "null"];
};
